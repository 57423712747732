import * as React from "react"

import {Layout} from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/section";

const NotFoundPage = () => (
    <Layout>
        <Section>
            <Seo title="404: Not found"/>
            <h1 className={'font-extrabold text-xl'}>404: Not Found</h1>
            <p>The page you requested does not exist.</p>
        </Section>
    </Layout>
)

export default NotFoundPage;
